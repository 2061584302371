import { motion } from 'framer-motion';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Dialog, { type DialogRef, useDialogContext } from '~/components/Dialog';
import { cn } from '~/utils/cn';

interface ActivatedBoostDialogProps {
  dialogRef: React.RefObject<DialogRef>;
}

const SubscriptionDialog: React.FC<ActivatedBoostDialogProps> = ({
  dialogRef,
}) => {
  return (
    <Dialog ref={dialogRef}>
      <DialogWrapper>
        <SubscriptionInfo />
      </DialogWrapper>
    </Dialog>
  );
};

export default SubscriptionDialog;

/* Wrapper */
const variants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

const DialogWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { open } = useDialogContext();
  const transitionDuration = 300;

  return (
    <Dialog.Content
      transitionDuration={transitionDuration}
      overlayClassName="grid place-items-center place-content-center z-50"
    >
      <motion.div
        initial="hidden"
        animate={open ? 'visible' : 'hidden'}
        variants={variants}
        transition={{
          duration: transitionDuration / 1000,
          ease: 'easeInOut',
        }}
        className={cn(
          'fixed inset-0 pointer-events-none',
          'bg-black/10 backdrop-blur-sm'
        )}
      ></motion.div>
      <div>
        <div
          className={cn(
            'fixed -translate-y-1/2 -translate-x-1/2',
            'overflow-auto rounded-lg'
          )}
        >
          <motion.div
            initial="hidden"
            animate={open ? 'visible' : 'hidden'}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 },
            }}
            transition={{
              duration: transitionDuration / 1000,
              ease: 'easeInOut',
            }}
            className={cn(
              'w-[90svw] sm:w-[32rem] py-8 px-10',
              'flex flex-col gap-7 justify-center items-start',
              'overflow-auto rounded-lg',
              'bg-black border border-white/15'
            )}
          >
            <motion.div
              initial="hidden"
              animate={open ? 'visible' : 'hidden'}
              variants={variants}
              transition={{
                duration: transitionDuration / 1000,
                ease: 'easeInOut',
              }}
              className={cn(
                'absolute z-50 top-4 right-4',
                'will-change-opacity'
              )}
            >
              <Dialog.Close
                className={cn(
                  'rounded-full p-1 group',
                  'focus:outline-none focus-visible:bg-white/5'
                )}
              >
                <XIcon />
              </Dialog.Close>
            </motion.div>
            {children}
          </motion.div>
        </div>
      </div>
    </Dialog.Content>
  );
};

const XIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={cn('size-5 text-skyblue text-white')}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.8}
      d="M6 18L18 6M6 6l12 12"
    />
  </svg>
);

const SubscriptionInfo: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="space-y-4">
      <p className="font-inter font-medium text-white text-xl">
        {t('subscription.title')}
      </p>
      <div className="space-y-2.5">
        <p className="font-inter text-white/90 text-sm">
          {t('subscription.description')}
        </p>
        <p className="font-inter text-white/90 text-sm">
          {t('subscription.thanks')}
        </p>
      </div>
    </div>
  );
};
