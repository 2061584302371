import { useSearchParams } from '@remix-run/react';

import { languageMenu } from '~/modules/language/languageMenu';
import useRootData from '~/utils/root';

export function LanguageSwitcher(): React.ReactElement {
  const { locale } = useRootData();

  const currentLanguage = languageMenu.find((lang) => lang.locale === locale);

  const setSearchParam = useSearchParams()[1];

  function handleChange() {
    const form = document.getElementById('language-form') as HTMLFormElement;
    setSearchParam({ lng: form.language.value });
  }

  return (
    <form id="language-form" method="post">
      <select
        name="language"
        value={currentLanguage?.locale}
        onChange={handleChange}
        className="font-sFPro font-normal text-md text-white bg-transparent border-none focus:outline-none cursor-pointer"
      >
        <option value={languageMenu[0].locale}>{languageMenu[0].icon}</option>
        <option value={languageMenu[1].locale}>{languageMenu[1].icon}</option>
      </select>
    </form>
  );
}
