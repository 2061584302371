import useRootData from '~/utils/root';

export function DygestLogo() {
  const { locale } = useRootData();

  return (
    <a
      href={`/?lng=${locale}`}
      className="flex items-center justify-center cursor-pointer"
    >
      <img
        src="/images/dygest-logo.svg"
        alt="Dygest logo"
        className="h-10 w-9"
      />
    </a>
  );
}
