import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useId,
  useInteractions,
  useMergeRefs,
  useRole,
  useTransitionStyles,
} from '@floating-ui/react';
import React from 'react';
import { flushSync } from 'react-dom';

import useRootData from '~/utils/root';

// import H from './H';
// import P from './P';

interface DialogOptions {
  initialOpen?: boolean | undefined;
  viewRef?: React.RefObject<string | undefined> | undefined;
}

export interface DialogRef {
  init: () => void;
  open: () => void;
  close: () => void;
}

const Dialog = React.forwardRef(function Dialog(
  { children, ...options }: React.PropsWithChildren<DialogOptions>,
  ref: React.ForwardedRef<DialogRef>
) {
  const dialog = useDialog(options, ref);
  return (
    <DialogContext.Provider value={dialog}>{children}</DialogContext.Provider>
  );
}) as DialogComponent;

/* Hooks */
export function useDialog(
  { initialOpen = false, viewRef }: DialogOptions = {},
  ref: React.ForwardedRef<DialogRef>
) {
  const { isMobile } = useRootData();
  const [open, setOpen] = React.useState(initialOpen);
  const [labelId, setLabelId] = React.useState<string | undefined>();
  const [descriptionId, setDescriptionId] = React.useState<
    string | undefined
  >();

  React.useImperativeHandle(
    ref,
    () => ({
      init: () => runTransition({ viewRef, isMobile, setOpen, open: true }),
      open: () => setOpen(true),
      close: () => setOpen(false),
    }),
    [isMobile, viewRef]
  );

  const toggleOpen = React.useCallback(
    (open: boolean) => runTransition({ viewRef, isMobile, setOpen, open }),
    [isMobile, viewRef]
  );

  const data = useFloating({ open, onOpenChange: toggleOpen });
  const context = data.context;

  const click = useClick(context);
  const dismiss = useDismiss(context, {
    outsidePressEvent: 'mousedown',
    // Guard check to prevent dismissing when clicking inside the toast
    outsidePress: (event) =>
      !(event.target as Element)?.closest('[data-toast]'),
  });
  const role = useRole(context);

  const interactions = useInteractions([click, dismiss, role]);

  return React.useMemo(
    () => ({
      open,
      setOpen: toggleOpen,
      ...interactions,
      ...data,
      labelId,
      descriptionId,
      setLabelId,
      setDescriptionId,
    }),
    [open, toggleOpen, interactions, data, labelId, descriptionId]
  );
}

/* Components */
interface DialogTriggerProps {
  children: React.ReactNode;
  asChild?: boolean;
  as?: React.ElementType;
}

const DialogTrigger = React.forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement> & DialogTriggerProps
>(function DialogTrigger({ children, asChild = false, as, ...props }, propRef) {
  const context = useDialogContext();
  const childrenRef = (children as unknown as { ref: React.Ref<HTMLElement> })
    .ref;
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

  // `asChild` allows the user to pass any element as the anchor
  if (asChild && React.isValidElement(children)) {
    return React.cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...(typeof children.props === 'object' ? children.props : {}),
        'data-state': context.open ? 'open' : 'closed',
      })
    );
  }

  if (as) {
    return React.createElement(
      as,
      {
        ref,
        ...context.getReferenceProps(props),
      },
      children
    );
  }

  return (
    <button
      ref={ref}
      // The user can style the trigger based on the state
      data-state={context.open ? 'open' : 'closed'}
      {...context.getReferenceProps(props)}
    >
      {children}
    </button>
  );
});

interface DialogContentProps {
  overlayClassName?: string;
  transitionDuration?: number;
}

const DialogContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement> & DialogContentProps
>(function DialogContent(
  { overlayClassName, transitionDuration, ...props },
  propRef
) {
  const { context: floatingContext, ...context } = useDialogContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);

  const { isMounted } = useTransitionStyles(floatingContext, {
    duration: transitionDuration,
  });
  if (!isMounted) return null;

  return (
    <FloatingPortal>
      <FloatingOverlay className={overlayClassName} lockScroll>
        <FloatingFocusManager context={floatingContext}>
          <div
            ref={ref}
            aria-labelledby={context.labelId}
            aria-describedby={context.descriptionId}
            {...context.getFloatingProps(props)}
          >
            {props.children}
          </div>
        </FloatingFocusManager>
      </FloatingOverlay>
    </FloatingPortal>
  );
});

// interface DialogHeadingProps {
//   variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
// }

const DialogHeading = React.forwardRef<
  HTMLHeadingElement,
  React.HTMLProps<HTMLHeadingElement>
>(function DialogHeading({ children, ...props }, ref) {
  const { setLabelId } = useDialogContext();
  const id = useId();

  // Only sets `aria-labelledby` on the Dialog root element
  // if this component is mounted inside it.
  React.useLayoutEffect(() => {
    setLabelId(id);
    return () => setLabelId(undefined);
  }, [id, setLabelId]);

  return (
    <h2 {...props} ref={ref} id={id}>
      {children}
    </h2>
  );
});

// interface DialogDescriptionProps {
//   variant:
//     | 'subtitle1'
//     | 'subtitle2'
//     | 'subtitle3'
//     | 'body1'
//     | 'body2'
//     | 'body3'
//     | 'caption';
// }

const DialogDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLProps<HTMLParagraphElement>
>(function DialogDescription({ children, ...props }, ref) {
  const { setDescriptionId } = useDialogContext();
  const id = useId();

  // Only sets `aria-describedby` on the Dialog root element
  // if this component is mounted inside it.
  React.useLayoutEffect(() => {
    setDescriptionId(id);
    return () => setDescriptionId(undefined);
  }, [id, setDescriptionId]);

  return (
    <p {...props} ref={ref} id={id}>
      {children}
    </p>
  );
});

const DialogClose = React.forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement>
>(function DialogClose(props, ref) {
  const { setOpen } = useDialogContext();
  return (
    <button type="button" {...props} ref={ref} onClick={() => setOpen(false)} />
  );
});

/* Context */
type ContextType =
  | (ReturnType<typeof useDialog> & {
      setLabelId: React.Dispatch<React.SetStateAction<string | undefined>>;
      setDescriptionId: React.Dispatch<
        React.SetStateAction<string | undefined>
      >;
    })
  | null;

const DialogContext = React.createContext<ContextType>(null);

export const useDialogContext = () => {
  const context = React.useContext(DialogContext);

  if (context == null) {
    throw new Error('Dialog components must be wrapped in <Dialog />');
  }

  return context;
};

/* Compound */
Dialog.Trigger = DialogTrigger;
Dialog.Content = DialogContent;
Dialog.Heading = DialogHeading;
Dialog.Description = DialogDescription;
Dialog.Close = DialogClose;

type DialogComponent = React.ForwardRefExoticComponent<
  React.PropsWithoutRef<React.PropsWithChildren<DialogOptions>> &
    React.RefAttributes<DialogRef>
> & {
  Trigger: typeof DialogTrigger;
  Content: typeof DialogContent;
  Heading: typeof DialogHeading;
  Description: typeof DialogDescription;
  Close: typeof DialogClose;
};

/* Export */
export default Dialog;

/* Transitions */
function setTransitionName(id: string, name: string) {
  document.getElementById(id)?.style.setProperty('view-transition-name', name);
}
function clearTransitionName(id: string) {
  document.getElementById(id)?.style.removeProperty('view-transition-name');
}

interface TransitionConfig {
  isMobile: boolean;
  viewRef: React.RefObject<string | undefined> | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
}

function runTransition({ viewRef, isMobile, setOpen, open }: TransitionConfig) {
  const viewId = viewRef?.current;
  const skipTransition = isMobile && !open;
  if (viewId && document.startViewTransition && !skipTransition) {
    const before = open ? `${viewId}-trigger` : `${viewId}-content`;
    const after = open ? `${viewId}-content` : `${viewId}-trigger`;
    setTransitionName(before, viewId);
    document
      .startViewTransition(() => {
        clearTransitionName(before);
        flushSync(() => setOpen(open));
        setTransitionName(after, viewId);
      })
      .finished.then(() => {
        clearTransitionName(after);
      })
      .catch((err) => {
        console.warn('[View Transition]', err);
      });
  } else {
    setOpen(open);
  }
}
