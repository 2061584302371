import React from 'react';

const FACEBOOK_PIXEL_ID = '470421295925891';

const facebookPixelCode = `
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', ${FACEBOOK_PIXEL_ID});
  fbq('track', 'PageView');
  `;

export function FacebookPixel() {
  const [isClient, setIsClient] = React.useState(false);

  React.useEffect(() => {
    setIsClient(true);
  }, []);

  // To prevent hydration mismatch, we only render the script on the client
  if (!isClient || process.env.NODE_ENV === 'development') {
    return null;
  }

  return (
    <>
      <script
        dangerouslySetInnerHTML={{
          __html: facebookPixelCode,
        }}
      />
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${FACEBOOK_PIXEL_ID}&ev=PageView&noscript=1" />`,
        }}
      />
    </>
  );
}
