import { parseWithZod } from '@conform-to/zod';
import {
  type ActionFunctionArgs,
  type LinksFunction,
  type LoaderFunctionArgs,
  data,
  json,
  redirect,
} from '@remix-run/node';
import {
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
  useRouteLoaderData,
} from '@remix-run/react';
import { captureRemixErrorBoundaryError } from '@sentry/remix';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import MobileDetect from 'mobile-detect';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useChangeLanguage } from 'remix-i18next/react';
import './tailwind.css';
import './styles/fonts.css';
import { z } from 'zod';

import { sendMessage } from './firebase/sendMessage';
import i18next from './i18next.server';
import type { Locale } from './modules/language/@types/Locales';
import { USER_SESSION_KEY, getSession } from './modules/session/session.server';
import { createCustomerPortalSession } from './modules/stripe/handlers/createCustomerPortalSession';
import { findCustomerByFirebaseUid } from './modules/stripe/handlers/findCustomerByFirebaseUid';
import { Footer } from './routes/components/Footer/Footer';
import { Navbar } from './routes/components/NavBar';
import {
  AuthContext,
  type AuthCore,
  type AuthStore,
  createAuthStore,
} from './store/AuthStore';
import { FacebookPixel } from './utils/FacebookPixel';
import { getUserSubscriptionStatus } from './utils/getUserSubscriptionStatus';
import { type RootData } from './utils/root';
import { extractClientUrl } from './utils/url';

export const links: LinksFunction = () => [
  // Preconnect for Firebase and Google Analytics
  { rel: 'preconnect', href: 'https://www.googletagmanager.com' },
  { rel: 'preconnect', href: 'https://firebase.googleapis.com' },
];

/* -------------------------------------------------------------------------- */
/*                                   LOADER                                   */
/* -------------------------------------------------------------------------- */

export async function loader({ request }: LoaderFunctionArgs) {
  const userAgent = request.headers.get('User-Agent');
  const _locale = await i18next.getLocale(request);
  const locale = (_locale ?? 'en') as Locale;

  const { isPremium, firebaseUid, subscriptionProvider } =
    await getUserSubscriptionStatus(request);

  const mobileDetect = new MobileDetect(userAgent as string);
  const isMobile = !!mobileDetect.mobile();
  const isTablet = !!mobileDetect.tablet();
  const os = mobileDetect.os();
  const isIOS = os === 'iOS';
  const isAndroidOS = os === 'AndroidOS';

  return json({
    config: {
      CLIENT_URL: extractClientUrl(request),
    },
    locale,
    isIOS,
    isAndroidOS,
    isMobile,
    isTablet,
    isPremium,
    firebaseUid,
    subscriptionProvider,
  } satisfies RootData);
}

/* -------------------------------------------------------------------------- */
/*                                   ACTION                                   */
/* -------------------------------------------------------------------------- */

export const contactSchema = z.object({
  email: z
    .string({ required_error: '' })
    .email('contact.error.email.invalid')
    .max(200, 'contact.error.email.too_long'),
  message: z
    .string({ required_error: '' })
    .min(2, 'contact.error.message.too_short')
    .max(2000, 'contact.error.message.too_long'),
});

export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData();
  const session = await getSession(request);
  const firebaseUid: string | null = session.get(USER_SESSION_KEY) ?? null;

  switch (formData.get('_action')) {
    case 'contact': {
      const submission = parseWithZod(formData, { schema: contactSchema });
      if (submission.status !== 'success') {
        return submission.reply();
      }
      await sendMessage({
        firebaseUid,
        contactEmail: submission.value.email,
        message: submission.value.message,
      });
      return submission.reply();
    }
    case 'stripe-portal': {
      if (!firebaseUid) {
        return data({ error: 'No user session' }, { status: 400 });
      }
      const customer = await findCustomerByFirebaseUid(firebaseUid);
      if (!customer) {
        return data({ error: 'No customer found' }, { status: 404 });
      }
      const portalUrl = await createCustomerPortalSession({
        customerId: customer.id,
      });
      if (!portalUrl) {
        return data(
          { error: 'Failed to create billing portal session' },
          { status: 500 }
        );
      }
      return redirect(portalUrl);
    }
    default:
      console.debug('Unhandled action type', formData.get('_action'));
      return data({ error: 'Unhandled action type' }, { status: 400 });
  }
}

/* -------------------------------------------------------------------------- */
/*                                   LAYOUT                                   */
/* -------------------------------------------------------------------------- */

function LayoutInner({ children }: React.PropsWithChildren) {
  const data = useRouteLoaderData<typeof loader>('root') as
    | RootData
    | undefined;

  const { i18n } = useTranslation();
  const locale = data?.locale ?? i18n.language;
  useChangeLanguage(locale);

  return (
    <html lang={locale} dir={i18n.dir()} id="root">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        <FacebookPixel />
      </head>
      <body>
        <AuthProvider initData={data}>{children}</AuthProvider>
        <Scripts />
        <ScrollRestoration />
      </body>
    </html>
  );
}

export function Layout({ children }: React.PropsWithChildren) {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // With SSR, we usually want to set some default staleTime
            // above 0 to avoid refetching immediately on the client
            // https://react-query.tanstack.com/guides/ssr#stale-time
            staleTime: 60 * 1000,
          },
        },
      })
  );

  return (
    <QueryClientProvider client={queryClient}>
      <LayoutInner>{children}</LayoutInner>
    </QueryClientProvider>
  );
}

export const ErrorBoundary = () => {
  const { t } = useTranslation();

  const error = useRouteError();
  captureRemixErrorBoundaryError(error);

  return (
    <div className="flex flex-col items-center h-screen mt-32">
      <div className="flex flex-row gap-4 items-center">
        <h1 className="font-inter font-bold text-3xl text-red-600">
          {t('error.notFound.code')}
        </h1>
        <h1 className="font-inter font-bold text-3xl text-white">
          {t('error.notFound.title')}
        </h1>
        <Link to="/" className="flex flex-row items-center gap-2">
          <p className="font-inter font-normal text-2xl text-white">
            {t('error.notFound.back')}
          </p>
          <img
            src="/images/error/home.svg"
            alt="home"
            className="h-10 w-10 mb-2 cursor-pointer hover:scale-110 transition-transform duration-300 ease-in-out"
          />
        </Link>
      </div>
      <h1 className="font-inter font-normal text-2xl text-white">
        {t('error.notFound.subtitle')}
      </h1>
    </div>
  );
};

export default function App() {
  const { ready } = useTranslation();

  if (!ready) {
    return null;
  }

  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
    </>
  );
}

/* -------------------------------------------------------------------------- */
/*                                AUTH PROVIDER                               */
/* -------------------------------------------------------------------------- */

interface AuthProviderProps {
  initData?: AuthCore;
}

export const AuthProvider: React.FC<
  React.PropsWithChildren<AuthProviderProps>
> = ({ initData, children }) => {
  const storeRef = React.useRef<AuthStore>(undefined);

  if (!storeRef.current && initData) {
    storeRef.current = createAuthStore(initData);
  }

  if (!storeRef.current) {
    return children;
  }

  return (
    <AuthContext.Provider value={storeRef.current}>
      {children}
    </AuthContext.Provider>
  );
};
