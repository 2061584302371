import React from 'react';
import { useTranslation } from 'react-i18next';

import type { DialogRef } from '~/components/Dialog';

import ContactUsDialog from './ContactUsDialog';

export function Footer() {
  const { t } = useTranslation();
  const dialogRef = React.useRef<DialogRef>(null);

  const openDialog = () => {
    dialogRef.current?.open();
  };

  return (
    <footer className="bg-background2 text-white text-opacity-70">
      <div className="xl:mx-32 lg:mx-32 md:mx-16 sm:mx-8 xs:mx-4 mx-4 py-8">
        <div className="relative hidden lg:flex justify-between">
          <div>
            <button
              onClick={openDialog}
              className="font-sFProText font-normal text-16 leading-24 mr-8"
            >
              {t('footer.contact')}
            </button>
            <a
              href={CGU}
              target="_blank"
              className="font-sFProText font-normal text-16 leading-24 mr-8"
              rel="noreferrer"
            >
              {t('footer.cgu')}
            </a>
            <a
              href={PRIVACY_POLICY}
              target="_blank"
              className="font-sFProText font-normal text-16 leading-24 mr-8"
              rel="noreferrer"
            >
              {t('footer.privacy')}
            </a>
          </div>
          <div className="flex">
            <p className="font-sFProText font-normal text-16 leading-24 text-white">
              {t('footer.eco')}
            </p>
            <span className="mx-1"></span>
            <p className="font-sFProText font-normal text-16 leading-24">
              {t('footer.dygest')}
            </p>
          </div>
        </div>
        <div className="relative show lg:hidden flex flex-col gap-2">
          <div className="flex flex-col gap-2">
            <button
              onClick={openDialog}
              className="font-sFProText font-normal text-16 leading-24 mr-8 text-left"
            >
              {t('footer.contact')}
            </button>
            <a
              href={CGU}
              target="_blank"
              className="font-sFProText font-normal text-16 leading-24"
              rel="noreferrer"
            >
              {t('footer.cgu')}
            </a>
            <a
              href={PRIVACY_POLICY}
              target="_blank"
              className="font-sFProText font-normal text-16 leading-24"
              rel="noreferrer"
            >
              {t('footer.privacy')}
            </a>
          </div>
          <div className="flex flex-row gap-1">
            <p className="font-sFProText font-normal text-16 leading-24 text-white">
              {t('footer.eco')}
            </p>
            <p className="font-sFProText font-normal text-16 leading-24">
              {t('footer.dygest')}
            </p>
          </div>
        </div>
      </div>
      <ContactUsDialog dialogRef={dialogRef} />
    </footer>
  );
}

/* link */
// TODO: replace the link to a privacy policy route idem for CGU
// const PRIVACY_POLICY = "https://dygest.co/privacy_policy/";
const PRIVACY_POLICY =
  'https://docs.google.com/document/d/15ozp__ofNOoFuMhoql84V_LoNs7iOtvRevCi_8q6yLo';
const CGU =
  'https://docs.google.com/document/d/1tcRB2_w9BL4CeJkJ1xamC0dvvNZDVP70e3HOpD6ieEQ';
