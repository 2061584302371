import {
  type SubmissionResult,
  getFormProps,
  getInputProps,
  useForm,
} from '@conform-to/react';
import { getZodConstraint, parseWithZod } from '@conform-to/zod';
import { Form, useActionData, useNavigation } from '@remix-run/react';
import { motion } from 'framer-motion';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import Dialog, { type DialogRef, useDialogContext } from '~/components/Dialog';
import LoadingSpinner from '~/components/LoadingSpinner';
import { contactSchema } from '~/root';
import { cn } from '~/utils/cn';

interface ActivatedBoostDialogProps {
  dialogRef: React.RefObject<DialogRef>;
}

const ContactUsDialog: React.FC<ActivatedBoostDialogProps> = ({
  dialogRef,
}) => {
  const onClose = () => {
    dialogRef.current?.close();
  };

  return (
    <Dialog ref={dialogRef}>
      <DialogWrapper>
        <ContactUs onClose={onClose} />
      </DialogWrapper>
    </Dialog>
  );
};

/* Wrapper */
const variants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

const DialogWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { open } = useDialogContext();
  const transitionDuration = 300;

  return (
    <Dialog.Content
      transitionDuration={transitionDuration}
      overlayClassName="grid place-items-center place-content-center z-50"
    >
      <motion.div
        initial="hidden"
        animate={open ? 'visible' : 'hidden'}
        variants={variants}
        transition={{
          duration: transitionDuration / 1000,
          ease: 'easeInOut',
        }}
        className={cn(
          'fixed inset-0 pointer-events-none',
          'bg-black/40 backdrop-blur-sm',
          'will-change-opacity'
        )}
      ></motion.div>
      <div>
        <div
          className={cn(
            'fixed -translate-y-1/2 -translate-x-1/2',
            'overflow-auto sm:rounded-lg'
          )}
        >
          <motion.div
            initial="hidden"
            animate={open ? 'visible' : 'hidden'}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 },
            }}
            transition={{
              duration: transitionDuration / 1000,
              ease: 'easeInOut',
            }}
            className={cn(
              'h-svh w-svw sm:h-fit sm:w-[24rem]',
              'flex flex-col gap-7 justify-center items-center',
              'overflow-auto sm:rounded-lg',
              'bg-background'
            )}
          >
            <motion.div
              initial="hidden"
              animate={open ? 'visible' : 'hidden'}
              variants={variants}
              transition={{
                duration: transitionDuration / 1000,
                ease: 'easeInOut',
              }}
              className={cn(
                'absolute z-50 top-5 right-5',
                'will-change-opacity'
              )}
            >
              <Dialog.Close
                className={cn(
                  'rounded-full p-1 group',
                  'focus:outline-none focus-visible:bg-white/5'
                )}
              >
                <XIcon />
              </Dialog.Close>
            </motion.div>
            {children}
          </motion.div>
        </div>
      </div>
    </Dialog.Content>
  );
};

const XIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={cn('size-5 text-skyblue text-white')}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.8}
      d="M6 18L18 6M6 6l12 12"
    />
  </svg>
);

/* Content */
const TITLE_KEY = 'contact.title';
const SUBTITLE_KEY = 'contact.subtitle';

interface ContactUsProps {
  onClose: () => void;
}

const ContactUs: React.FC<ContactUsProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const lastResult = useActionData<
    SubmissionResult<string[]> | null | undefined
  >();
  const [form, { email, message }] = useForm<z.input<typeof contactSchema>>({
    lastResult,
    constraint: getZodConstraint(contactSchema),
    shouldValidate: 'onBlur',
    shouldRevalidate: 'onInput',
    onValidate({ formData }) {
      return parseWithZod(formData, { schema: contactSchema });
    },
  });
  const emailError = email.errors?.[0] ? t(email.errors?.[0]) : null;
  const messageError = message.errors?.[0] ? t(message.errors?.[0]) : null;
  const isSubmittable =
    !hasErrors(form.errors) &&
    !hasErrors(email.errors) &&
    !hasErrors(message.errors) &&
    !!email.value &&
    !!message.value;

  React.useEffect(() => {
    if (lastResult?.status === 'success') {
      onClose();
    }
  }, [lastResult, onClose]);

  return (
    <Form
      method="POST"
      {...getFormProps(form)}
      className={cn(
        'w-full py-10 px-8',
        'flex-1 flex flex-col gap-5 justify-content items-center'
      )}
    >
      <div className="w-full flex flex-col items-center gap-3">
        <h1 className={cn('text-white font-medium text-3xl')}>
          {t(TITLE_KEY)}
        </h1>
        <p className={cn('text-white/90 text-sm text-justify')}>
          {t(SUBTITLE_KEY)}
        </p>
      </div>
      <fieldset className="w-full flex-1 space-y-6">
        <div className="relative flex-1 flex flex-col items-start gap-1">
          <p className="text-white p-1">{t('contact.email')} :</p>
          <input
            placeholder={t('contact.placeholder.email')}
            className={cn(
              'w-full px-3 py-2',
              'text-base',
              'bg-[#1C1C1E] rounded-md',
              'border',
              email.errors ? 'border-red-500' : 'border-white/10',
              'focus:outline-none focus-visible:border-white/50',
              'text-white placeholder-white/30'
            )}
            {...getInputProps(email, { type: 'email' })}
          />
          <div className="absolute -bottom-5">
            {emailError ? (
              <p className="text-red-500 text-xs px-1">{emailError}</p>
            ) : null}
          </div>
        </div>
        <div className="w-full flex flex-col items-start gap-1">
          <p className="text-white p-1">{t('contact.message')} :</p>
          <textarea
            placeholder={t('contact.placeholder.message')}
            className={cn(
              'w-full px-3 py-3 min-h-48',
              'rounded-md bg-[#1C1C1E]',
              'border border-white/10',
              'focus:outline-none focus-visible:border-white/50',
              'text-white placeholder-white/30',
              'text-base'
            )}
            {...getInputProps(message, { type: 'text' })}
          />
          <div className="absolute -bottom-5">
            {messageError ? (
              <p className="text-red-500 text-xs px-1">{messageError}</p>
            ) : null}
          </div>
        </div>
        <Button isSubmittable={isSubmittable} />
      </fieldset>
    </Form>
  );
};

export default ContactUsDialog;

interface SubmitButtonProps {
  isSubmittable: boolean;
}

const Button: React.FC<SubmitButtonProps> = ({ isSubmittable }) => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const isSubmitting =
    navigation.state === 'submitting' || navigation.state === 'loading';

  return (
    <div className="w-full pt-4">
      <button
        type="submit"
        name="_action"
        value="contact"
        aria-label={t('contact.send')}
        disabled={!isSubmittable || isSubmitting}
        className={cn(
          'group relative',
          'w-full h-11 rounded-md',
          isSubmittable ? 'bg-white/90' : 'bg-white/50',
          'transition-colors',
          'focus:outline-none focus-visible:bg-blue-600'
        )}
      >
        <p
          className={cn(
            'text-[#1C1C1E] text-lg font-medium font-inter',
            isSubmitting && 'opacity-0',
            'group-focus-visible:text-white',
            'transition-colors'
          )}
        >
          {t('contact.send')}
        </p>
        <div className="absolute inset-0 flex items-center justify-center">
          {isSubmitting && <LoadingSpinner className="text-[#1C1C1E]" />}
        </div>
      </button>
    </div>
  );
};

/* Utils */
const hasErrors = (errors?: Record<string, unknown> | string[]) =>
  errors && Object.keys(errors).length > 0;
