import { Form, Link, useNavigate, useNavigation } from '@remix-run/react';
import { motion } from 'framer-motion';
import React from 'react';
import { useTranslation } from 'react-i18next';

import BurgerMenu from '~/components/BurgerMenu';
import type { DialogRef } from '~/components/Dialog';
import { LanguageSwitcher } from '~/components/LanguageSwitcher';
import LoadingSpinner from '~/components/LoadingSpinner';
import {
  useIsConnected,
  useIsPremium,
  useSubscriptionProvider,
} from '~/store/AuthStore';
import { useSetOnOnboarding } from '~/store/onboarding';
import { cn } from '~/utils/cn';

import { AppStoreButton } from '../AppStoreButton';

import { DygestLogo } from './components';
import SubscriptionDialog from './components/SubscriptionModal';

export const Navbar: React.FC = () => {
  const isConnected = useIsConnected();
  const isPremium = useIsPremium();

  return (
    <header
      style={{
        background:
          'linear-gradient(139.45deg, rgba(19, 19, 19, 0.75) 0%, rgba(14, 14, 14, 0.9) 99.95%)',
      }}
      className="relative flex justify-between items-center
                xl:mx-32 lg:mx-32 md:mx-16 sm:mx-8 xs:mx-4 mx-4
                rounded-3xl border-[1px] border-solid border-white border-opacity-[6%]
                shadow-navBar mt-4 py-4 px-8"
    >
      <div className="flex items-center gap-4">
        <DygestLogo />
        <LanguageSwitcher />
      </div>
      {/* <NavBarLinks /> */}
      <div className="flex flex-row items-center justify-center gap-2">
        {!isConnected && <SignIn />}
        <div className="relative hidden md:flex items-center gap-4">
          <div className="flex items-center gap-2">
            {isConnected && <Logout toggleMenu={() => {}} />}
            {isPremium && <MySubscription />}
          </div>
          <AppStoreButton />
        </div>
        <BurgerMenu />
      </div>
    </header>
  );
};

export const SignIn: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const setOnOnboarding = useSetOnOnboarding();

  const onClick = React.useCallback(() => {
    setOnOnboarding();

    navigate('/');
  }, [setOnOnboarding, navigate]);

  return (
    <motion.button
      onClick={onClick}
      className={cn(
        'flex flex-row items-center justify-center',
        'cursor-pointer hover:bg-opacity-70',
        'h-[32px] rounded-[8px] bg-green bg-opacity-80',
        'pt-2 pb-2 pl-3 pr-3 gap-2'
      )}
      rel="noreferrer"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <p className="font-sFProDisplay font-[600] text-white text-sm leading-5">
        {t('navbar.signIn')}
      </p>
    </motion.button>
  );
};

interface LogoutProps {
  toggleMenu: () => void;
}
export const Logout: React.FC<LogoutProps> = ({ toggleMenu }) => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const isSubmitting =
    navigation.state === 'submitting' &&
    navigation.location?.pathname === '/logout';

  return (
    <Link to="/logout" replace className="text-white">
      <motion.button
        onClick={toggleMenu}
        className={cn(
          'flex flex-row items-center justify-center',
          'cursor-pointer',
          'relative px-3 py-2',
          'border border-white/20 rounded-lg',
          'shadow-sm shadow-white/10',
          'hover:bg-white/10 transition-colors'
        )}
        rel="noreferrer"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <p
          className={cn(
            'font-sFProDisplay font-[300] text-white text-xs leading-4',
            isSubmitting && 'opacity-0'
          )}
        >
          {t('navbar.logout')}
        </p>
        {isSubmitting && (
          <div className="absolute inset-0 flex items-center justify-center">
            <LoadingSpinner className="size-3.5 text-white" />
          </div>
        )}
      </motion.button>
    </Link>
  );
};

export const MySubscription: React.FC = () => {
  const { t } = useTranslation();
  const dialogRef = React.useRef<DialogRef>(null);
  const subscriptionProvider = useSubscriptionProvider();

  return subscriptionProvider === 'stripe' ? (
    <StripePortalButton />
  ) : (
    <>
      <SubscriptionButton onClick={() => dialogRef.current?.open()}>
        {t('navbar.subscription')}
      </SubscriptionButton>
      <SubscriptionDialog dialogRef={dialogRef} />
    </>
  );
};

const StripePortalButton: React.FC = () => {
  const { t } = useTranslation();

  const navigation = useNavigation();
  const isSubmitting =
    navigation.state === 'submitting' &&
    navigation.formData?.get('_action') === 'stripe-portal';

  return (
    <Form method="post">
      <SubscriptionButton
        type="submit"
        name="_action"
        value="stripe-portal"
        isLoading={isSubmitting}
      >
        {t('navbar.subscription')}
      </SubscriptionButton>
    </Form>
  );
};

interface SubscriptionButtonProps {
  onClick?: () => void;
  type?: 'submit' | 'button';
  name?: string;
  value?: string;
  isLoading?: boolean;
}

const SubscriptionButton: React.FC<
  React.PropsWithChildren<SubscriptionButtonProps>
> = ({ children, onClick, type = 'button', name, value, isLoading }) => (
  <motion.button
    onClick={onClick}
    type={type}
    name={name}
    value={value}
    className={cn(
      'relative px-3 py-2 flex items-center justify-center',
      'cursor-pointer border border-white/20 rounded-lg',
      'shadow-sm shadow-white/10 hover:bg-white/10 transition-colors'
    )}
    whileTap={{ opacity: 0.7 }}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.5 }}
  >
    <p
      className={cn(
        'font-sFProDisplay font-[300] text-white text-xs leading-4',
        isLoading && 'opacity-0'
      )}
    >
      {children}
    </p>
    {isLoading && (
      <div className="absolute inset-0 flex items-center justify-center">
        <LoadingSpinner className="size-3.5 text-white" />
      </div>
    )}
  </motion.button>
);
