import type { Language } from './@types/Locales';

export const languageMenu: Language[] = [
  {
    locale: 'en',
    icon: '🇬🇧',
  },
  {
    locale: 'fr',
    icon: '🇫🇷',
  },
];
