import { motion } from 'framer-motion';
import { useState } from 'react';

import { AppStoreButton } from '~/routes/components/AppStoreButton';
import { Logout, MySubscription } from '~/routes/components/NavBar/NavBar';
import { useIsConnected, useIsPremium } from '~/store/AuthStore';
import { cn } from '~/utils/cn';

const BurgerMenu: React.FC = () => {
  const isConnected = useIsConnected();
  const isPremium = useIsPremium();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <>
      <button
        className={cn(
          'relative z-50 flex flex-col items-center justify-center w-8 h-8',
          !isOpen && 'md:hidden'
        )}
        onClick={toggleMenu}
      >
        <motion.span
          className="block h-1 w-8 bg-white mb-1"
          animate={isOpen ? { rotate: 45, y: 8 } : { rotate: 0, y: 0 }}
        />
        <motion.span
          className="block h-1 w-8 bg-white mb-1"
          animate={isOpen ? { opacity: 0 } : { opacity: 1 }}
        />
        <motion.span
          className="block h-1 w-8 bg-white"
          animate={isOpen ? { rotate: -45, y: -8 } : { rotate: 0, y: 0 }}
        />
      </button>

      <motion.div
        initial={{ x: '100%' }}
        animate={{ x: isOpen ? 0 : '100%' }}
        transition={{ type: 'spring', stiffness: 80, damping: 15 }}
        className="fixed inset-0 bg-black bg-opacity-90 z-40 flex flex-col items-center justify-center text-white"
      >
        <ul className="flex flex-col items-center gap-5">
          <AppStoreButton />

          <div className="flex flex-col items-center gap-2 ">
            {isConnected && <Logout toggleMenu={toggleMenu} />}
            {isPremium && <MySubscription />}
          </div>
        </ul>
      </motion.div>
    </>
  );
};

export default BurgerMenu;
